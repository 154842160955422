<script>
import Layout from "@/layouts/main.vue"
import PageHeader from "@/components/page-header.vue";
import NotFound from "@/components/not-found.vue";
import DataLoader from "@/components/data-loader.vue";
import MemberBasicInformation from "@/components/dashboard/user/member/member-basic-information.vue";
import API from "@/plugins/axios-method";
import UserAuthorisedPhoneNumber from "@/components/dashboard/user/user-authorised-phone-number.vue";
import ReferralInformations from "@/components/dashboard/user/referral-informations.vue";
// import MemberAccountsInformation from "@/components/dashboard/user/member/member-accounts-information.vue";
import StatCard from "@/components/dashboard/StatCard.vue";
import ClientPagination from "@/components/dashboard/ClientPagination.vue";
import UserStatisticTableCard from "@/components/dashboard/user/user-statistic-table-card.vue";
import MemberAccountsInformation from "@/components/dashboard/user/member/member-accounts-information.vue";
export default {
  name: "single-commercial",
  components: {
    MemberAccountsInformation,
    UserStatisticTableCard, ClientPagination,
    StatCard,
    // MemberAccountsInformation,
    ReferralInformations,
    UserAuthorisedPhoneNumber, MemberBasicInformation, DataLoader, NotFound, PageHeader, Layout},
  data(){
    return {
      user: null,
      paginatedUsers: [],
      users: [],
      pageNumber: 1,
      itemsPerPage: 10,
      showDataLoader: true,
      notFound: false,
      commercialStats: {
        activeAccounts: 0,
        godsons: 0,
        totalSavings: 0,
        totalRegistration: 0
      }
    }
  },
  mounted() {
    this.getSingleCommercial()
    this.getMembers()
  },
  methods: {
    getSingleCommercial(){
      API.get('/users/statistics/' + this.$route.params.id)
          .then(response => {
            this.user = response.data.activeUser

            this.commercialStats.activeAccounts = response.data.activeAccounts
            this.commercialStats.godsons = response.data.users
            this.commercialStats.totalSavings = response.data.deposit
            this.commercialStats.totalRegistration = response.data.registration
          })
          .catch(e => {
            if(e.response.status === 404){
              this.notFound = true
              this.showDataLoader = false
            }
          })
    },
    paginateUsers(array, pageNumber, itemsPerPage = 10){
      const startIndex = (pageNumber - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return array.slice(startIndex, endIndex);
    },
    getMembers(){
      API.get('/users/'+ this.$route.params.id +'/members')
          .then(response => {
            if (response.status === 200){
              response.data["hydra:member"].map(user => {
                this.users.push(user)
              })
              this.paginatedUsers = this.paginateUsers(this.users, this.pageNumber, this.itemsPerPage)
            }
          })
          .finally(() => {
            this.showDataLoader = false
          })
    },
    formatPhoneNumber(phoneNumber){
      return phoneNumber?.split('237')[1]
    },
    nextPage(){
      this.setCurrentPage('next')
      this.paginatedUsers = this.paginateUsers(this.users, this.pageNumber, this.itemsPerPage)
    },
    previousPage(){
      this.setCurrentPage('previous')
      this.paginatedUsers = this.paginateUsers(this.users, this.pageNumber, this.itemsPerPage)
    },
    navigate(page){
      this.pageNumber = page
      this.paginatedUsers = this.paginateUsers(this.users, this.pageNumber, this.itemsPerPage)
    },
    setCurrentPage(direction){
      if (direction === 'next'){
        if ( this.pageNumber < (this.users.length / this.itemsPerPage) ) this.pageNumber = this.pageNumber + 1
      } else  {
        if (this.pageNumber > 1) this.pageNumber = this.pageNumber - 1
      }
    },
  },
  computed: {
    canShowData(){
      return !this.showDataLoader && this.user
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader
        :show-search="false"
        :show-add-button="false"
        v-if="canShowData"
    />
    <DataLoader v-if="!canShowData"/>
    <NotFound v-if="!showDataLoader && notFound"/>
    <section v-if="canShowData">
      <b-row>
        <b-col md="4">
          <member-basic-information :user="user" />
        </b-col>
        <b-col md="4">
          <user-authorised-phone-number :user="user" />
        </b-col>
        <b-col md="4">
          <referral-informations
              v-if="user.referralInformations"
              :referral="user.referralInformations"
          />
        </b-col>
      </b-row>
      <b-row>
        <div class="" v-if="canShowData">
          <div class="card">
            <div class="card-body">
              <!-- Nav tabs -->
              <ul class="nav nav-tabs mb-3" role="tablist">
                <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#home" role="tab" aria-selected="false">
                    Statistiques
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link active" data-bs-toggle="tab" href="#product1" role="tab"
                     aria-selected="false">
                    Comptes
                  </a>
                </li>
              </ul>
              <!-- Tab panes -->
              <div class="tab-content  text-muted">
                <div class="tab-pane" id="home" role="tabpanel">
                  <b-row>
                    <StatCard
                        :amount="commercialStats.totalRegistration"
                        :is-money="true"
                        :is-increase="true"
                        :percentage="65"
                        title="Frais d'Inscriptions"
                    />
                    <StatCard
                        :amount="commercialStats.totalSavings"
                        :is-money="true"
                        :is-increase="true"
                        :percentage="65"
                        title="Epargne Totale"
                    />
                    <StatCard
                        :amount="commercialStats.activeAccounts"
                        :is-increase="true"
                        :icon="'shopping-bag'"
                        :icon-bg="'primary'"
                        :percentage="65"
                        title="Compte avec produit"
                    />
                    <StatCard
                        :amount="commercialStats.godsons"
                        :is-increase="true"
                        :icon="'user-circle'"
                        :icon-bg="'primary'"
                        :percentage="65"
                        title="Membres"
                    />
                  </b-row>
                  <b-card>
                    <b-card-body>
                      <UserStatisticTableCard
                          v-if="paginateUsers.length > 0"
                          :users="paginatedUsers"
                          :role="'member'"
                      />
                      <ClientPagination
                          :next="users.length > 0"
                          :currentPage="this.pageNumber"
                          :totalItems="users.length"
                          :itemsPerPage="this.itemsPerPage"
                          @navigate="navigate"
                          @nextPage="nextPage"
                          @previousPage="previousPage"
                      />
                    </b-card-body>
                  </b-card>
                </div>
                <div class="tab-pane active" id="product1" role="tabpanel">
                  <b-row>
                    <b-row>
                      <b-col md="3" v-for="(a, index) in user.accounts" :key="index">
                        <member-accounts-information :account="a" />
                      </b-col>
                    </b-row>
                  </b-row>
                </div>
              </div>
            </div><!-- end card-body -->
          </div><!-- end card -->
        </div>
      </b-row>
    </section>
  </Layout>
</template>

<style scoped>

</style>